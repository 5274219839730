import {
  LOGIN,
  LOGOUT,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_FAIL,
  OAUTH_LOGIN_SUCCESS,
} from '../actions/login';
const initialState = {
  isLoggedIn: false,
  userToken: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
    case OAUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userToken: action.data,
      };
    case VALIDATE_TOKEN:
      return {
        ...state,
        userToken: action.data,
        isLoggedIn: true,
      };
    case VALIDATE_TOKEN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
