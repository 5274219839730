import sketch from '../../logo/roster-backgrounds/sketchdefaultcourt.jpg';

// const RosterBackground = (leagueCategory, leagueName) => {
const RosterBackground = () => {
  // if (leagueCategory === 'Youth') return youth;
  // if (leagueCategory === 'Korean') return hkkba;
  // if (leagueCategory === 'Alumni') return alumni;
  // switch (leagueName) {
  //   case 'Pro-Amateur':
  //     return proAm;
  //   case 'Division 1':
  //     return d1;
  //   case 'Division 2':
  //     return d2;
  //   case 'Division 3':
  //     return d3;
  //   case 'Division 4':
  //     return d4;
  //   case 'Division 5':
  //     return zerve;
  //   case 'Division 6':
  //     return sketchD6;
  //   case 'Corporate Division 1':
  //     return corporateD1;
  //   case 'Corporate Division 2':
  //     return corporateD2;
  //   case 'Corporate Division 3':
  //     return corporateD3;
  //   default:
  return sketch;
  // }
};

export default RosterBackground;
