import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { oauthLoginSuccess } from '../../store/actions/login';
import { validateToken } from '../../store/actions/login';

const OAuthCallback = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const accessToken = params.get('access_token');

        if (accessToken) {
          localStorage.setItem('sketch-token', accessToken);
          dispatch(oauthLoginSuccess(accessToken));
          dispatch(validateToken());

          // Get the stored return path and remove it from localStorage
          const returnTo = localStorage.getItem('auth_redirect') || '/';
          localStorage.removeItem('auth_redirect');

          // Redirect to the stored path
          history.replace(returnTo);
          return;
        }

        history.replace('/login?login_error=Authentication failed');
      } catch (error) {
        console.error('Auth callback error:', error);
        history.replace('/login?login_error=Authentication failed');
      }
    };

    handleCallback();
  }, [location, history, dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div>Processing login...</div>
    </div>
  );
};

export default OAuthCallback;
