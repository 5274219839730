import React from 'react';
import Login from './Login';
import Account from './Account';
import './LoginBox.scss';
import { useSelector } from 'react-redux';

const LoginBox = (props) => {
  const { isLoggedIn } = useSelector((store) => store.loginReducer);

  const handleClose = (e) => {
    if (e.target.classList.contains('login-overlay')) {
      props.toggle();
    }
  };

  // Don't render anything if not visible
  if (!props.isVisible) {
    return null;
  }

  return (
    <>
      {isLoggedIn ? (
        <Account onMouseLeave={props.toggle} />
      ) : (
        <div className='login-modal'>
          <div className='login-overlay' onClick={handleClose}></div>
          <div className='login-content'>
            <Login onClose={props.toggle} />
          </div>
        </div>
      )}
    </>
  );
};

export default LoginBox;
