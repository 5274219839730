import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleLogo from '../../logo/google_logo.png';
import { login } from '../../store/actions/login';
import './Login.scss';

const Login = ({ onClose }) => {
  const [loading, setLoading] = useState({
    google: false,
    facebook: false,
    regular: false,
  });
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const loginError = params.get('login_error');
    if (loginError) {
      setError(loginError);
    }
  }, [location]);

  const handleRegularLogin = async (e) => {
    e.preventDefault();
    setLoading((prev) => ({ ...prev, regular: true }));
    setError(null);

    try {
      await dispatch(login(username, password));
      setLoading((prev) => ({ ...prev, regular: false }));
      onClose();
    } catch (err) {
      setLoading((prev) => ({ ...prev, regular: false }));
      setError('Invalid username or password');
    }
  };

  const handleOAuthLogin = async (provider) => {
    setLoading((prev) => ({ ...prev, [provider]: true }));
    setError(null);

    try {
      // Store the current path before redirecting
      const returnTo = window.location.pathname + window.location.search;
      localStorage.setItem('auth_redirect', returnTo);

      const frontendCallback = `${window.location.origin}/auth/callback`;
      const oauthUrl = `${
        process.env.REACT_APP_API_PATH
      }/auth/login/${provider}?frontend_redirect=${encodeURIComponent(frontendCallback)}`;

      window.location.href = oauthUrl;
    } catch (err) {
      setLoading((prev) => ({ ...prev, [provider]: false }));
      setError(err.message || 'Failed to connect to login service');
    }
  };

  const handleCreateAccount = () => {
    onClose();
    history.push('/register');
  };

  const handleForgotPassword = () => {
    onClose();
    history.push('/find');
  };

  return (
    <div className='login-box'>
      <div className='login-text-wrapper'>
        <span className='login-text'>Sign In</span>
      </div>

      {error && <div className='login-error'>{error}</div>}

      <form className='login-form' onSubmit={handleRegularLogin}>
        <input
          type='text'
          placeholder='Username'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className='login-button' disabled={loading.regular}>
          {loading.regular ? <div className='button-loader'></div> : 'Login'}
        </button>
        <button type='button' className='signup-button' onClick={handleCreateAccount}>
          Create Account
        </button>
        <button type='button' className='forgot-password-button' onClick={handleForgotPassword}>
          Forgot Password?
        </button>

        <div className='or-divider'>OR</div>

        <div className='social-buttons'>
          <button
            className='google-btn'
            onClick={() => handleOAuthLogin('google')}
            disabled={loading.google}
          >
            <div className='google-icon-wrapper'>
              <img className='google-icon' src={GoogleLogo} alt='Google sign-in' />
            </div>
            <span className='btn-text'>Sign in with Google</span>
          </button>
          <button
            className='facebook-btn'
            onClick={() => handleOAuthLogin('facebook')}
            disabled={loading.facebook}
          >
            <div className='facebook-icon-wrapper'>
              <FacebookIcon className='facebook-icon' />
            </div>
            <span className='btn-text'>Continue with Facebook</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
