import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import fbLogo from '../../logo/facebook.png';
import igLogo from '../../logo/insta.png';

const Footer = (props) => {
  return (
    <div className='footer' style={props}>
      <div className='footer-content'>
        <div className='footer-content-left'>
          <span>Copyright © 2021 SKETCH Basketball. All rights reserved.</span>
          <span>
            No portion of sketchbball.com may be duplicated, redistributed or manipulated in any
            form.
          </span>
          <span>
            By accessing any information beyond this page, you agree to abide by the
            sketchbball.com.
          </span>
        </div>
        <div className='footer-content-right'>
          <div className='footer-links'>
            <Link to='/privacy' className='footer-link'>
              Privacy Policy
            </Link>
          </div>
          <a
            href='https://www.instagram.com/sketchcompetition_official/'
            target='_blank'
            rel='noreferrer'
          >
            <img src={igLogo} className='ig-logo' alt='ig-logo' />
          </a>
          <a
            href='https://www.facebook.com/sketchballcompetition/'
            target='_blank'
            rel='noreferrer'
          >
            <img src={fbLogo} className='fb-logo' alt='fb-logo' />
          </a>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default Footer;
