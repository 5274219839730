import React from 'react';
import leagueThumbnail from '../logo/sketch-league-tn.png';
import youthThumbnail from '../logo/sketch-youth-tn.png';
import hkkbaWhkkbaThumbnail from '../logo/hkkba_logo.png';
import corporateThumbnail from '../logo/sketch-corporate-tn.png';
import sketchAcademyThumbnail from '../logo/sketch-club-tn.png';
import eventsLeagueThumbnail from '../logo/sketch-worldcup-tn.png';
import internationalThumbnail from '../logo/sketch-international-tn.png';
import koreaThumbnail from '../logo/sketch-korea-tn.png';
import landingPageBanner from '../logo/landing-page-banner.jpg';
import './LandingPage.scss';
import { NavLink } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className='landing-page-wrapper'>
      <div className='landing-home-wrapper'>
        <div className='landing-home-banner-wrapper'>
          <img
            src={landingPageBanner}
            className='landing-home-banner-image'
            alt='home-banner-image'
          />
        </div>
        {/* first block */}
        <div className='sub-content-box'>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Sketch'>
              <img src={leagueThumbnail} className='grid-item academy' alt='logo' />
              <span> SKETCH Basketball Competiton</span>
            </NavLink>
          </div>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Premium'>
              <img src={corporateThumbnail} className='grid-item' alt='logo' />
              <span> SKETCH Premium League </span>
            </NavLink>
          </div>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Asian'>
              <img src={internationalThumbnail} className='grid-item' alt='logo' />
              <span> SKETCH International </span>
            </NavLink>
          </div>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Youth'>
              <img src={youthThumbnail} className='grid-item' alt='logo' />
              <span> SKETCH Youth League </span>
            </NavLink>
          </div>
        </div>
        {/* second block */}
        <div className='sub-content-box'>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Training/Schedules'>
              <img src={sketchAcademyThumbnail} className='grid-item academy' alt='logo' />
              <span> SKETCH Basketball Club</span>
            </NavLink>
          </div>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Sketch-Korea'>
              <img src={koreaThumbnail} className='grid-item events-league' alt='logo' />
              <span> SKETCH Korea</span>
            </NavLink>
          </div>

          <div className='main-grid-item-wrapper'>
            <NavLink to='/Korean'>
              <img src={hkkbaWhkkbaThumbnail} className='grid-item' alt='logo' />
              <span> 홍콩한인농구협회 HKKBA </span>
            </NavLink>
          </div>
          <div className='main-grid-item-wrapper'>
            <NavLink to='/Others'>
              <img src={eventsLeagueThumbnail} className='grid-item events-league' alt='logo' />
              <span> SKETCH World Cup </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
