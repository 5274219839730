import sketch from '../logo/post_logo_sketch.png';
import sketchBanner from '../logo/match-banner/sketch_banner.png';
import hongkongBanner from '../logo/match-banner/hong_kong_banner.jpg';
import alumniBanner from '../logo/match-banner/alumni_banner.jpg';
import proamBanner from '../logo/match-banner/proam_banner.png';
import beginnersBanner from '../logo/match-banner/beginners_banner.jpg';
import corporateBanner from '../logo/match-banner/corporate_banner.jpg';
import hkkbaBanner from '../logo/match-banner/hkkba_banner.jpg';
import internationalBanner from '../logo/match-banner/sketch_international_banner.jpg';
import worldCupBanner from '../logo/match-banner/sketch_world_cup_banner.jpg';
import vipBanner from '../logo/match-banner/vip_banner.jpg';
import youthBanner from '../logo/match-banner/youth_banner.jpg';

const sponsorSelector = (leagueAbv, leagueCategory = '') => {
  let sponsor = '';
  let postLogo = '';
  let sponsorBanner = '';

  if (leagueCategory == 'Youth') {
    sponsor = 'Sketch';
    postLogo = sketch;
    sponsorBanner = youthBanner;
  } else if (leagueCategory == 'Asian') {
    sponsor = 'Sketch';
    postLogo = sketch;
    sponsorBanner = internationalBanner;
  } else if (leagueCategory == 'Others') {
    sponsor = 'Sketch';
    postLogo = sketch;
    sponsorBanner = worldCupBanner;
  } else {
    switch (leagueAbv) {
      case 'Hong':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = hongkongBanner;
        break;
      case 'Kong':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = hongkongBanner;
        break;
      case 'Alumni Division':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = alumniBanner;
        break;
      case 'Beginners':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = beginnersBanner;
        break;
      case 'Corporate Hong':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = corporateBanner;
        break;
      case 'Corporate Kong':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = corporateBanner;
        break;
      case 'HKKBL':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = hkkbaBanner;
        break;
      case 'Youth':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = youthBanner;
        break;
      case 'Sketch International':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = internationalBanner;
        break;
      case 'Sketch World Cup':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = worldCupBanner;
        break;
      case 'VIP Division':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = vipBanner;
        break;
      case 'Sketch Pro Am':
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = proamBanner;
        break;
      default:
        sponsor = 'Sketch';
        postLogo = sketch;
        sponsorBanner = sketchBanner;
    }
  }

  const sponsorObject = {
    sponsor: sponsor,
    postLogo: postLogo,
    sponsorBanner: sponsorBanner,
  };

  return sponsorObject;
};

export default sponsorSelector;
