import React from 'react';
import './Privacy.scss';

const Privacy = () => {
  return (
    <div className='privacy-container'>
      <div className='privacy-content'>
        <h1>Privacy Policy</h1>
        <p className='last-updated'>Last Updated: 2025-01-15</p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Sketch Basketball Website. We are committed to protecting your personal
            information and your right to privacy. This Privacy Policy explains how we collect, use,
            and protect your information when you use our application.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <h3>2.1 Information you provide to us:</h3>
          <ul>
            <li>Email address</li>
            <li>Username</li>
            <li>Password (encrypted)</li>
            <li>Basic profile information</li>
            <li>Authentication data through third-party services (Google, Facebook)</li>
          </ul>

          <h3>2.2 Information automatically collected:</h3>
          <ul>
            <li>Log data</li>
            <li>Device information</li>
            <li>Usage information</li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and maintain our services</li>
            <li>Authenticate your identity</li>
            <li>Communicate with you about our services</li>
            <li>Improve our services</li>
            <li>Protect our services and users</li>
          </ul>
        </section>

        <section>
          <h2>4. Data Security</h2>
          <p>
            We implement appropriate technical and organizational security measures to protect your
            information, including:
          </p>
          <ul>
            <li>Password encryption</li>
            <li>Secure data storage</li>
            <li>Regular security assessments</li>
            <li>Limited access to personal information by our staff</li>
          </ul>
        </section>

        <section>
          <h2>5. Third-Party Services</h2>
          <p>
            Our application uses third-party authentication services (Google, Facebook) for user
            convenience. Please note that these services have their own privacy policies that govern
            their use of your information.
          </p>
        </section>

        <section>
          <h2>6. Data Storage and Processing</h2>
          <p>
            All data is stored and processed in accordance with Hong Kong data protection laws. We
            retain your information only for as long as necessary to provide our services and
            fulfill the purposes outlined in this policy.
          </p>
        </section>

        <section>
          <h2>7. Your Rights</h2>
          <p>Under Hong Kong privacy laws, you have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Withdraw consent for data processing</li>
          </ul>
        </section>

        <section>
          <h2>8. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes
            by posting the new Privacy Policy on this page.
          </p>
        </section>

        <section>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
            sketchbballdev@gmail.com or contact us through the website.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
