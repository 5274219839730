import React, { useEffect, useState } from 'react';
import headerLogo from '../../logo/logo.png';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AccountItem from './AccountItem';
import LoginBox from './LoginBox';
import landingPageHeaderMenu from '../../const/landingPageHeaderMenu';
import MenuItem from './MenuItem';
import './DefaultHeader.scss';

const DefaultHeader = () => {
  const { isLoggedIn, userToken } = useSelector((store) => store.loginReducer);
  const [isLoginVisible, setIsLoginVisible] = useState(false);

  useEffect(() => {
    const footer = document.querySelector('.footer');
    footer.setAttribute('style', 'background-color: #1B1B1B; color: white');
  }, []);

  const toggleShowAccountBox = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoginVisible(false);
    }
  }, [isLoggedIn]);

  return (
    <div className='default-header-wrapper'>
      <div className='default-page-header'>
        <div className='default-page-header-contents'>
          <div className='default-page-logo-wrapper'>
            <NavLink to='/'>
              <img src={headerLogo} className='default-page-header-logo' alt='logo' />
            </NavLink>
          </div>
          {landingPageHeaderMenu.map((item, index) => {
            return <MenuItem key={index} title={item.title} path={item.path} />;
          })}
        </div>
        <AccountItem isLoggedIn={isLoggedIn} userToken={userToken} onClick={toggleShowAccountBox} />
      </div>
      {(!isLoggedIn || isLoginVisible) && (
        <LoginBox
          isLoggedIn={isLoggedIn}
          toggle={toggleShowAccountBox}
          isVisible={isLoginVisible}
        />
      )}
    </div>
  );
};

export default DefaultHeader;
