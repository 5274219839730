import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { findMyUsername, sendPasswordReset } from '../store/actions/user';
import './FindMyAccount.scss';

const FindMyAccount = () => {
  const dispatch = useDispatch();
  const [emailFind, setEmailFind] = useState('');
  const [emailSend, setEmailSend] = useState('');
  const [username, setUsername] = useState('');

  const handleFindId = async (e) => {
    e.preventDefault();
    document.body.style.cursor = 'wait';
    await dispatch(findMyUsername(emailFind));
    document.body.style.cursor = 'default';
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    document.body.style.cursor = 'wait';
    await dispatch(sendPasswordReset(emailSend, username));
    document.body.style.cursor = 'default';
  };

  return (
    <div className='find-account-wrapper'>
      <div className='find-account-content'>
        <div className='find-account-box'>
          <div className='find-account-text-wrapper'>
            <span className='find-account-text'>Find My Account</span>
          </div>

          <form className='find-id-form' onSubmit={handleFindId}>
            <div className='form-section'>
              <div className='section-title'>Find My ID</div>
              <input
                type='email'
                placeholder='Email'
                value={emailFind}
                onChange={(e) => setEmailFind(e.target.value)}
              />
              <button className='submit-button' type='submit'>
                Find User ID
              </button>
            </div>
          </form>

          <div className='separator'>
            <span>OR</span>
          </div>

          <form className='reset-password-form' onSubmit={handleResetPassword}>
            <div className='form-section'>
              <div className='section-title'>Reset Password</div>
              <input
                type='email'
                placeholder='Email'
                value={emailSend}
                onChange={(e) => setEmailSend(e.target.value)}
              />
              <input
                type='text'
                placeholder='Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <button className='submit-button' type='submit'>
                Send Password Reset Email
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FindMyAccount;
